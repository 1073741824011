import React from 'react';
import { injectIntl } from "gatsby-plugin-react-intl"

class SideNavDropDown extends React.Component {

    createOptions = () => {
        let navigation = [];
        navigation.push({
            label: this.props.intl.formatMessage({id: "shared.jumpTo"}),
            url: null
        });

        this.props.navigation.forEach((nav) => {
            navigation.push({
                label: nav.label,
                url: nav.url
            });

            if (nav.submenu) {
                nav.submenu.forEach((subnav) => {
                    navigation.push({
                        label: '- ' + subnav.label,
                        url: subnav.url
                    });

                    if (subnav.submenu) {
                        subnav.submenu.forEach((subsubnav) => {
                            navigation.push({
                                label: '-- ' + subsubnav.label,
                                url: subsubnav.url
                            });

                            if (subsubnav.submenu) {
                                subsubnav.submenu.forEach((subsubsubnav) => {
                                    navigation.push({
                                        label: '--- ' + subsubsubnav.label,
                                        url: subsubsubnav.url
                                    });

                                    if (subsubnav.submenu) {
                                        subsubnav.submenu.forEach((subsubsubsubnav) => {
                                            navigation.push({
                                                label: '---- ' + subsubsubsubnav.label,
                                                url: subsubsubsubnav.url
                                            });
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        let items = navigation.map((nav, i) => {
            return <option key={i} value={nav.url}>{nav.label}</option>
        });

        return items;
    }

    handleJumpToPage = (e) => {
        e.persist();
        if (e && e.target && e.target.value) {
            // Ignore the static 'Jump to:' option.
            if (this.props.intl.formatMessage({id: "shared.jumpTo"}) === e.target.value) return;

            if (/sdk/.test(document.location.pathname)) {
                // let path = null;
                // if (/react-native/.test(document.location.pathname) || /cordova/.test(document.location.pathname) || /flutter/.test(document.location.pathname)) {
                //     let parts = document.location.pathname.split("/");
                //     path = '/' + parts[1] + '/' + parts[2] + '/' + parts[3] + '/' + parts[4] + e.target.value;
                // } else {
                //     let parts = document.location.pathname.split("/");
                //     path = '/' + parts[1] + '/' + parts[2] + '/' + parts[3] + e.target.value;
                // }

                let parts = document.location.pathname.split("/");
                let path = '/' + parts[1] + '/' + parts[2] + '/' + parts[3] + e.target.value;

                window.location.href = path;
            } else {
               window.location.href = e.target.value;
            }
        }
    }

    render() {
        return(
          <div className={`navigation-dropdown-container`}>
              <select className={`navigation-dropdown`} onChange={this.handleJumpToPage} onBlur={this.handleJumpToPage}>
                  {this.createOptions()}
              </select>
          </div>
        )
    }
}

export default injectIntl(SideNavDropDown)
