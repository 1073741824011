import React from 'react';
import {Nav} from 'react-bootstrap';
import {injectIntl} from "gatsby-plugin-react-intl"

class TabsTwoBlocks extends React.Component {

  onSelectedTab = (e) => {
    let el = this.myTabs,
      parent = el.closest(".tabbed-two-blocks");
    if (e === 'tab1') {
      parent.querySelector(".tab1").style.display = 'block';
      parent.querySelector(".tab2").style.display = 'none';
    } else {
      parent.querySelector(".tab2").style.display = 'block';
      parent.querySelector(".tab1").style.display = 'none';
    }

  }

  render() {
      return (
        <div className={`overflow-tabs-wrapper`} ref={c => this.myTabs = c}>
          <div className={`overflow-tabs`}>
            <Nav variant="tabs" defaultActiveKey="tab1" onSelect={this.onSelectedTab}>
              <Nav.Item>
                <Nav.Link eventKey="tab1">{this.props.tab1}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tab2">{this.props.tab2}</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      )
  }
}

export default injectIntl(TabsTwoBlocks)
