import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function HeadingWrapper(component) {
  return ({ children }) => <Heading component={component}>{children}</Heading>;
}

export function Heading({ component: Component, children }) {
  const anchor = getAnchor(children);
  const link = `#${anchor}`;

  return (
    <Component id={anchor} className="anchored-heading">
      {children}

      <a href={link} className="heading-anchor">
        <FontAwesomeIcon icon={"link"} />
      </a>
    </Component>
  );
}

function getAnchor(text) {
  if (Array.isArray(text)) {
    return getAnchor(text[0]);
  }

  return text
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/[ ]/g, "-");
}
