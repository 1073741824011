import React from 'react';
import {Nav} from 'react-bootstrap';
import {injectIntl} from "gatsby-plugin-react-intl"

class TabsCodeBlocks extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      tab1: null,
      tab2: null,
      tab1Name: null,
      tab2Name: null,
      showTabs: false
    };
  }

  componentDidMount(){
    //These are the default tabs
    let tab1 = "objectivec",
      tab2 = "swift",
      tab1Name = "Objective-C",
      tab2Name= "Swift";

    //Can be overridden by passing props for tab1, tab2, tab1Name, tab2Name
    if (this.props.tab1) {
      tab1 = this.props.tab1;
    }

    if (this.props.tab2) {
      tab2 = this.props.tab2;
    }

    if (this.props.tab1Name) {
      tab1Name = this.props.tab1Name;
    }

    if (this.props.tab2Name) {
      tab2Name = this.props.tab2Name;
    }

    this.setState({
      tab1: tab1,
      tab2: tab2,
      tab1Name: tab1Name,
      tab2Name: tab2Name,
      showTabs: true
    });

  }

  componentDidUpdate(prevProps, prevState, snapshot){
    let el = this.myTabs,
      parent = el.closest(".tabbed-code-blocks");
    parent.querySelector("[data-language='" + this.state.tab1 + "']").style.display = 'inline-grid';
    parent.querySelector("[data-language='" + this.state.tab2 + "']").style.display = 'none';
  }

  onSelectedTab = (e) => {
    let el = this.myTabs,
      parent = el.closest(".tabbed-code-blocks");
    if (e === this.state.tab1) {
      parent.querySelector("[data-language='" + this.state.tab1 + "']").style.display = 'inline-grid';
      parent.querySelector("[data-language='" + this.state.tab2 + "']").style.display = 'none';
    } else {
      parent.querySelector("[data-language='" + this.state.tab2 + "']").style.display = 'inline-grid';
      parent.querySelector("[data-language='" + this.state.tab1 + "']").style.display = 'none';
    }

  }

  render() {
    if (this.state.showTabs) {
      return (
        <div ref={c => this.myTabs = c}>
          <Nav variant="tabs" defaultActiveKey={this.state.tab1} onSelect={this.onSelectedTab}>
            <Nav.Item>
              <Nav.Link eventKey={this.state.tab1}>{this.state.tab1Name}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={this.state.tab2}>{this.state.tab2Name}</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(TabsCodeBlocks)
