import React from 'react';
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class GuidesWarning extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showWarning: false
    };
  }

  componentDidMount() {

    if (/guides/.test(document.location.pathname)) {
      if (!/v3/.test(document.location.pathname)) {
        this.setState({
          showWarning: true
        });
      }
    }

  }

  render() {
    if (this.state.showWarning) {
      return (
        <div className={`guides-warning`}>
          <div className={`icon`}>
            <FontAwesomeIcon icon="exclamation-triangle" size={`2x`} />
          </div>
          <div className={`text`}>
            <FormattedMessage id="shared.guidesWarning" />
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}

export default injectIntl(GuidesWarning)
