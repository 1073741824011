import React from "react"

const CalloutBlock = ({ children }) => {

  return (
    <div className={`callout-block`}>
      {children}
    </div>
  )
}


export default CalloutBlock
