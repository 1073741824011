import React, { useEffect, useRef } from "react";
import { Nav } from "react-bootstrap";
import { injectIntl } from "gatsby-plugin-react-intl";
import { usePreferredLanguageContext } from "../../context/preferred-language";

function TabbedCodeBlocksComponent({ children }) {
  const { language, setLanguage } = usePreferredLanguageContext();
  const containerRef = useRef();

  const tabs = getTabs(children);
  const selectedTab = tabs.find((tab) => tab.language === language) || tabs[0];

  useEffect(function updateCodeBlocks() {
    tabs.forEach(({ language }) => {
      const container = containerRef.current;
      if (container) {
        container.querySelector(`[data-language='${language}']`).style.display = selectedTab.language === language ? "inline-grid" : "none";
      }
    });
  }, [tabs, selectedTab]);

  return (
    <div ref={containerRef} className="tabbed-code-blocks">
      <div>
        <Nav variant="tabs" activeKey={selectedTab.language} onSelect={setLanguage}>
          {tabs.map(({ language, label }) => (
            <Nav.Item key={language}>
              <Nav.Link eventKey={language}>{label}</Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>

      {children}
    </div>
  );
}

function getTabs(children) {
  const tabs = [];

  children.forEach((child) => {
    if (child.props && child.props["data-language"]) {
      const language = child.props["data-language"];
      const label = getTabLabel(language);

      tabs.push({ language, label });
    }
  });

  return tabs;
}

function getTabLabel(language) {
  switch (language) {
    case "kotlin":
      return "Kotlin";
    case "java":
      return "Java";
    case "swift":
      return "Swift";
    case "objc":
      return "Objective-C";
    case "javascript":
      return "JavaScript";
    default:
      throw new Error(`Unsupported language '${language}'.`);
  }
}

export const TabbedCodeBlocks = injectIntl(TabbedCodeBlocksComponent);
