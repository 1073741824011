import React from 'react';
import {injectIntl, Link} from "gatsby-plugin-react-intl"
import NavigationGuides from "../../content/side-nav-guides"
import NavigationGuidesV3 from "../../content/side-nav-guides-v3"
import NavigationSDK from "../../content/side-nav-sdk"
import NavigationAPI from "../../content/side-nav-api"
import SideNavDropDown from "./side-nav-dropdown"

class SideNav extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            navigation: []
        };
    }


    componentDidMount() {

        if (/guides/.test(document.location.pathname)) {
            if (/v3/.test(document.location.pathname)) {
                this.setState({
                    navigation: NavigationGuidesV3[this.props.intl.locale].nav
                });
            } else {
                this.setState({
                    navigation: NavigationGuides[this.props.intl.locale].nav
                });
            }
        } else if (/sdk/.test(document.location.pathname)) {
            if (/v3/.test(document.location.pathname)) {
                if (/v3\/web/.test(document.location.pathname)) {
                    const disallowedImplementationPages = [
                      '/implementation/loyalty/',
                      '/implementation/monetize/',
                      '/implementation/scannables/',
                      '/implementation/segmentation/',
                    ];

                    this.setState({
                        navigation: NavigationSDK[this.props.intl.locale].nav2
                          .map(item => {
                              if (item.url !== '/implementation/') {
                                  return item;
                              }

                              return {
                                  ...item,
                                  submenu: item.submenu.filter(subItem => !disallowedImplementationPages.includes(subItem.url))
                              }
                          })
                    });
                } else {
                    this.setState({
                        navigation: NavigationSDK[this.props.intl.locale].nav2
                    });
                }
            } else {
                this.setState({
                    navigation: NavigationSDK[this.props.intl.locale].nav
                });
            }
        } else if (/api/.test(document.location.pathname)) {
            this.setState({
                navigation: NavigationAPI[this.props.intl.locale].nav
            });
        }

        setTimeout(() => {

            let activeLink = document.querySelector('.active');

            if (activeLink) {

                let mainItem = activeLink.closest(".main-item");

                if (mainItem) {

                    let allSubMenus = mainItem.querySelectorAll(".submenu");

                    if (allSubMenus && allSubMenus.length > 0) {
                        allSubMenus.forEach((s) => {
                            s.style.display = "block";
                        });
                    }
                }
            }

        }, 10);

    }

    createURL = (url) => {
        let path = null;
        if (/guides/.test(document.location.pathname)) {
            path = url;
        } else if (/sdk/.test(document.location.pathname)) {
            if (/v3/.test(document.location.pathname)) {
                let parts = document.location.pathname.split("/");
                path = '/' + parts[1] + '/' + parts[2] + '/' + parts[3] + url;
            } else {
                if (/react-native/.test(document.location.pathname) || /cordova/.test(document.location.pathname) || /flutter/.test(document.location.pathname)) {
                    let parts = document.location.pathname.split("/");
                    path = '/' + parts[1] + '/' + parts[2] + '/' + parts[3] + '/' + parts[4] + url;
                } else {
                    let parts = document.location.pathname.split("/");
                    path = '/' + parts[1] + '/' + parts[2] + '/' + parts[3] + url;
                }
            }
        } else if (/api/.test(document.location.pathname)) {
            path = url;
        }
        return path;
    }

    render() {
        return (
            <>
                <SideNavDropDown navigation={this.state.navigation} />
                <ul className={`navigation-side guides`}>
                    {this.state.navigation.map((nav, i) => {
                        return (
                            <li key={i} className={`main-item`}>
                                <Link className={`link`} activeClassName={`active`} to={this.createURL(nav.url)}>{nav.label}</Link>

                                {nav.submenu &&
                                <ul className={`submenu`}>
                                    {nav.submenu.map((subnav, j) => {
                                        return (
                                            <li key={j} className={`submenu-item`}>
                                                <Link className={`sublink`} activeClassName={`active`} to={this.createURL(subnav.url)}>{subnav.label}</Link>

                                                {subnav.submenu &&
                                                <ul className={`submenu first-level`}>
                                                    {subnav.submenu.map((subsubnav, k) => {
                                                        return (
                                                        <li key={k} className={`submenu-item`}>
                                                            <Link className={`sublink`} activeClassName={`active`} to={this.createURL(subsubnav.url)}>{subsubnav.label}</Link>

                                                            {subsubnav.submenu &&
                                                            <ul className={`submenu second-level`}>
                                                                {subsubnav.submenu.map((subsubsubnav, l) => {
                                                                    return (
                                                                        <li key={l} className={`submenu-item`}>
                                                                            <Link className={`sublink`} activeClassName={`active`} to={this.createURL(subsubsubnav.url)}>{subsubsubnav.label}</Link>

                                                                            {subsubsubnav.submenu &&
                                                                                <ul className={`submenu third-level`}>
                                                                                {subsubsubnav.submenu.map((subsubsubsubnav, m) => {
                                                                                    return (
                                                                                    <li key={m} className={`submenu-item`}>
                                                                                        <Link className={`sublink`} activeClassName={`active`} to={this.createURL(subsubsubsubnav.url)}>{subsubsubsubnav.label}</Link>
                                                                                    </li>
                                                                                    )
                                                                                })}
                                                                                </ul>
                                                                            }

                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                            }

                                                        </li>
                                                        )
                                                    })}
                                                </ul>
                                                }

                                            </li>
                                        )
                                    })}
                                </ul>
                                }

                            </li>
                        )
                    })}
                </ul>
            </>
        )
    }
}

export default injectIntl(SideNav)
