import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {Container} from "react-bootstrap"
import Seo from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import HeroArticles from "../components/heroes/hero-articles"
import SideNav from "../components/misc/side-nav"
import CalloutBlock from "../components/misc/callout-block"
import TabsCodeBlocks from "../components/misc/tabs-code-blocks"
import TabsTwoBlocks from "../components/misc/tabs-two-blocks"
import SDKWarning from "../components/misc/sdk-warning"
import GuidesWarning from "../components/misc/guides-warning"
import { ExternalLink } from "../components/misc/external-link";
import { TabbedCodeBlocks } from "../components/misc/tabbed-code-blocks";
import { PreferredLanguageProvider } from "../context/preferred-language";
import { HeadingWrapper } from "../components/heading";

const shortcodes = {
  CalloutBlock,
  ExternalLink,
  TabbedCodeBlocks,
  TabsCodeBlocks,
  TabsTwoBlocks,
  // h1: HeadingWrapper('h1'),
  h2: HeadingWrapper('h2'),
  h3: HeadingWrapper('h3'),
  h4: HeadingWrapper('h4'),
  h5: HeadingWrapper('h5'),
  h6: HeadingWrapper('h6'),
}

class DocArticleTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;
    let titleParts = post.frontmatter.title.split("|");
    const title = titleParts[titleParts.length - 1];
    return (
      <PreferredLanguageProvider>
        <Layout>
          <Seo
            title={post.frontmatter.title}
            description={post.frontmatter.description}
          />
          <HeroArticles
              theme={`dark`}
              content={{title: title}}
          />
          <Container>
            <div className={`doc-articles`}>
              <div className={`side-nav`}>
                <SideNav />
              </div>
              <div className={`article-content`}>
                <SDKWarning />
                <GuidesWarning />
                <MDXProvider components={shortcodes}>
                  <MDXRenderer>{post.body}</MDXRenderer>
                </MDXProvider>
              </div>
            </div>
          </Container>
        </Layout>
      </PreferredLanguageProvider>
    )
  }
}

export default DocArticleTemplate

export const pageQuery = graphql`
  query DocArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        description
      }
    }
  }
`
