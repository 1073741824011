import React, { createContext, useContext, useEffect, useState } from "react";

const PreferredLanguageContext = createContext(undefined);

export const PreferredLanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState((typeof window !== 'undefined') ? localStorage.getItem('preferred_language') : "");

  useEffect(function storedPreferredLanguage() {
    if (typeof window !== 'undefined') {
      localStorage.setItem('preferred_language', language);
    }
  }, [language]);

  return (
    <PreferredLanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </PreferredLanguageContext.Provider>
  );
};

export function usePreferredLanguageContext() {
  const context = useContext(PreferredLanguageContext);
  if (context === undefined) {
    throw new Error('usePreferredLanguageContext must be used within a PreferredLanguageProvider');
  }

  return context;
}
